"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Time = /** @class */ (function () {
    function Time() {
    }
    /**
     * Get a nicely stringified version of relative time between two timestamps
     * @param timestamp Unix time {@link https://en.wikipedia.org/wiki/Unix_time}
     */
    Time.getRelativeTime = function (timestamp, currentTimestamp) {
        if (currentTimestamp === void 0) { currentTimestamp = Date.now(); }
        var secondsDifference = Math.abs((currentTimestamp - timestamp) / 1000);
        // If the timestamp is within the last few minutes,
        // then we should display the minute difference
        var minutesDifference = secondsDifference / 60;
        if (minutesDifference < 60) {
            var roundedTime = Math.round(minutesDifference);
            if (roundedTime === 0) {
                roundedTime = 1;
            }
            return roundedTime + "m ago";
        }
        // hour difference
        var hoursDifference = Math.round(minutesDifference / 60);
        if (hoursDifference < 24) {
            return hoursDifference + " hour" + (hoursDifference === 1 ? "" : "s") + " ago";
        }
        // day difference
        // (Month is simplified to 30 days)
        var days = Math.round(hoursDifference / 24);
        if (days < 30) {
            return days + " day" + (days === 1 ? "" : "s") + " ago";
        }
        // month difference
        // (Month is simplified to 30 days)
        var months = Math.round(days / 30);
        if (months < 12) {
            return months + " month" + (months === 1 ? "" : "s") + " ago";
        }
        // year difference
        var year = Math.round(months / 12);
        return year + " year" + (year === 1 ? "" : "s") + " ago";
    };
    return Time;
}());
exports.default = Time;
